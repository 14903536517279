import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { D } from '@dayetopia/library';
import alarm from '../images/alarm-icon.png';
import Button from '../components/Button';

export default function QuesLandingRightContent() {
  return (
    <RightContent>
      <AlarmBox>
        <AlarmStyled src={alarm}></AlarmStyled>
        <PStyled2>
          Before taking this questionnaire you should know your height, weight
          and a recent blood pressure reading. You can get your blood pressure
          tested at a number of places, including: your local GP surgery, most
          pharmacies, some workplaces.
        </PStyled2>
      </AlarmBox>
      <WarningBox>
        <PStyled2>
          Warning: We’re about to get real nosy! Some of the questions ahead
          will be highly personal.
        </PStyled2>
      </WarningBox>
      <LinkStyled to="/questionnaire-warning">
        <ButtonStyled>Start consultation</ButtonStyled>
      </LinkStyled>
    </RightContent>
  );
}

const LinkStyled = styled(Link)`
  width: 60%;
  &:hover {
    cursor: pointer;
  }
`;
const AlarmStyled = styled.img`
  position: absolute;
  top: -35px;
  right: 43%;
  height: 50px;
  @media only screen and (max-width: 500px) {
    right: 39%;
  }
`;

const PStyled2 = styled(D.P)`
  font-size: 16px;
  text-align: center;
  margin: 5px auto;
`;

const WarningBox = styled.div`
  border: 2px solid #00391e;
  width: 60%;
  padding: 0 20px;
  margin-bottom: 25px;
`;

const AlarmBox = styled.div`
  background-color: #eaf5e8;
  border: 2px solid #00391e;
  width: 70%;
  padding: 15px;
  position: relative;
  margin-bottom: 70px;
  @media only screen and (max-width: 500px) {
    margin-top: 10px;
    margin-bottom: 30px;
  }
`;

const ButtonStyled = styled(Button)`
  width: 100%;
  margin-bottom: 30px;
`;

const RightContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #cae2c3;
  height: calc(100vh - 100px);

  @media only screen and (max-width: 748px) {
    width: 100%;
    -webkit-flex: 0;
    flex-shrink: 0;
    padding-top: 30px;
  }
  @media only screen and (max-width: 500px) {
    height: auto;
    width: 100%;
  }
`;

import React from 'react';
import styled from 'styled-components';

import { D } from '@dayetopia/library';
import pills from '../images/img-pills.png';

export default function QuesLandingLeftContent() {
  function goArticle() {
    window.open(
      'https://yourdaye.com/vitals/womens-health/pros-and-cons-of-contraception/',
      '_blank'
    );
  }
  return (
    <LeftContent>
      <PillImage src={pills}></PillImage>
      <H1Styled>
        This questionnaire will help you figure out which contraceptive pill is
        right for you.{' '}
      </H1Styled>
      <PStyled>
        If you’d prefer a different contraceptive, check out Daye’s
        <StyledLink onClick={goArticle}>
          {' '}
          comprehensive guide to the pros and cons of different forms of
          contraception,{' '}
        </StyledLink>{' '}
        or speak to your GP.
      </PStyled>
    </LeftContent>
  );
}
const StyledLink = styled.span`
  cursor: pointer;
  display: inline;
  text-decoration: underline;
  font-weight: bold;
`;
const PillImage = styled.img`
  max-height: 150px;
  width: 20%;
  max-width: 130px;
  @media only screen and (max-width: 500px) {
    margin-top: 20px;
    max-width: 80px;
  }
`;

const H1Styled = styled(D.H1)`
  font-size: 28px;
  width: 70%;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
  @media only screen and (max-width: 500px) {
    font-size: 16px;
    margin: 20px;
  }
`;
const PStyled = styled(D.P)`
  font-size: 16px;
  width: 70%;
  text-align: center;
`;

const LeftContent = styled.div`
  background-color: #eaf5e8;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: calc(100vh - 100px);
  @media only screen and (max-width: 748px) {
    padding-top: 0px;
    padding-bottom: 30px;
    width: 100%;
    -webkit-flex: 0;
    flex-shrink: 0;
  }
  @media only screen and (max-width: 500px) {
    height: auto;
    width: 100%;
  }
`;

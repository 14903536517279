import React from 'react';
import styled from 'styled-components';
import QuesLandingLeftContent from '../components/QuesLandingLeftContent';
import QuesLandingRightContent from '../components/QuesLandingRightContent';

export default function QuestionnaireLanding() {
  return (
    <PageContainer>
      <PageContent>
        <QuesLandingLeftContent />
        <QuesLandingRightContent />
      </PageContent>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  position: relative;
  animation: fadein 0.8s;
`;

const PageContent = styled.div`
  display: flex;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
`;
